import type { TCabinCategory, TCabinPrice } from '@/helpers/api/graphql/queries/cabinCategoriesAvailability';
import type { TOptional } from '@/types';

export default class PriceMinMax {
  protected _max: number = -Infinity;
  protected _min: number = Infinity;

  static byCabinCategory(cabinCategory: TCabinCategory): PriceMinMax {
    const instance = new PriceMinMax();
    cabinCategory?.submetas?.forEach((subMeta) => {
      subMeta?.cabinTypes?.forEach((cabinType) => instance.update(cabinType?.lowestAvailablePrice?.totalPrice));
      instance.update(subMeta?.lowestAvailablePrice?.totalPrice);
    });
    return instance;
  }

  static byCabinCategories(cabinCategories: TCabinCategory[]): PriceMinMax {
    const instance = new PriceMinMax();
    cabinCategories?.forEach((cabinCategory) => instance.update(cabinCategory?.lowestSubCategoryPrice?.totalPrice));
    return instance;
  }

  update(priceDetails: TOptional<TCabinPrice>): void {
    if (priceDetails?.amount) {
      // Always taking value with tax
      const price = priceDetails.amount;

      const isFirstCall = this._min === Infinity && this._max === -Infinity;
      if (isFirstCall) {
        this._min = price;
        this._max = price;
        return;
      }

      if (price < this._min) {
        this._min = price;
      } else if (price > this._max) {
        this._max = price;
      }
    }
  }

  get max(): number {
    return this._max;
  }

  get min(): number {
    return this._min;
  }
}

/**
 * Google tag manager integration
 */
import omit from 'lodash/omit';

import trackerConstants from './constants';
import { trackEvent } from './dataLayer';
import eventProperties from './event/properties';
import getPage from './routesMapping';

const { BOOKING_GTM_MAPPING_V2, EVENT_ACTIONS, EVENTS, VV_EVENTS_ROOT_PREFIX } = trackerConstants;

const blacklistOptions = [
  'event',
  'location',
  'module',
  'actions',
  'label',
  'action',
  'category',
  'flyouts',
  'urlMap',
  'prefix',
];

/*
 * trackEvent() functions factory with defaults
 * Ex.:
 * const trackEvent = createTracker({
 *    prefix: trackerConstants.PREFIXES.PREFIX_1,
 *    event: trackerConstants.EVENTS.EVENT_1,
 *    module: trackerConstants.MODULES.MODULE_1,
 *    flyouts: [trackerConstants.FLYOUTS.FLYOUT_1, trackerConstants.FLYOUTS.FLYOUT_2],
 *    location: 'any_page_name',
 *    label:  trackerConstants.EVENT_LABELS.LABEL_1,
 * });
 * trackEvent({ action: trackerConstants.EVENT_ACTIONS.ACTION_1 });
 * will generate
 * {
 *   event: 'event-1',
 *   eventCategory: 'prefix_1-any_page_name|module_1|flyout_1-flyout_2',
 *   eventAction: 'action_1',
 *   eventLabel: 'label_1'
 * }
 */
export const createTracker = (defaultOptions) => (options) => {
  try {
    const {
      action,
      category,
      event = EVENTS.GTMevent,
      flyouts,
      label = '',
      location: urlPath,
      module,
      prefix = VV_EVENTS_ROOT_PREFIX,
      urlMap = BOOKING_GTM_MAPPING_V2,
    } = { ...defaultOptions, ...options };

    if (!action) {
      throw new Error("Tracker::trackGtmEvent: required param 'action' is missing");
    }

    const eventCategory =
      category ||
      eventProperties.getEventCategory({
        flyouts,
        module,
        prefix,
        urlMap,
        urlPath,
      });

    const trackableEvent = {
      event,
      eventAction: action,
      eventCategory,
      eventLabel: label,
      ...omit(options, blacklistOptions),
    };

    trackEvent(trackableEvent);
  } catch (err) {
    console.error('createTracker failed to track event', err);
  }
};

export const commonTracking = (eventAction, eventLabel, trackerEventCategory) => {
  const page = getPage();
  const eventPage = page === 'suites' ? 'choose-suites' : page;
  trackEvent({
    event: 'GTMevent',
    eventAction,
    eventCategory: trackerEventCategory && trackerEventCategory.length ? trackerEventCategory : `VV-${eventPage}`,
    eventLabel,
  });
};

export const trackButtonClick = ({ ...props }) => {
  createTracker({
    event: EVENTS.GTMevent,
  })({
    action: EVENT_ACTIONS.BUTTON_CLICK,
    ...props,
  });
};

export const trackExpandImageGallery = (cabinCode) => {
  const trackingData = {
    event: 'GTMevent',
    eventAction: 'icon-click',
    eventCategory: 'VV-choose-cabin',
    eventLabel: `expand-cabin-image|${cabinCode}`,
  };
  trackEvent(trackingData);
};

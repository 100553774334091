import type { LabelForTracking, LabelInFlyoutForTracking } from '@/tagmanager/types';

import constants from '@/tagmanager/constants';
import builders from '@/tagmanager/event/builders';
import { createTracker } from '@/tagmanager/tracker';

import { trackButtonClick } from './common';

const trackEvent = createTracker({
  event: constants.EVENTS.GTMevent,
});

export const trackBookNowClick = ({ label }: LabelForTracking) => {
  trackButtonClick({
    labels: [label],
  });
};

export const trackCompareCabinsClick = ({ label }: LabelForTracking) => {
  trackButtonClick({
    labels: [label],
  });
};

export const trackChooseCategoryToCompareApplied = ({ label }: LabelInFlyoutForTracking) => {
  const buildedLabel = builders.buildLabel([
    constants.EVENT_LABELS.MOBILE_BOOKING_FLOW.CHOOSE_CATEGORY_TO_COMPARE,
    label,
  ]);
  trackEvent({ action: constants.EVENT_ACTIONS.FILTER_APPLIED, label: buildedLabel });
};

export const trackCabinChooseClick = ({ label }: LabelForTracking) => {
  const buildedLabel = builders.buildLabel([constants.EVENT_LABELS.CHOOSE_CABIN, label]);

  trackButtonClick({
    labels: buildedLabel,
  });
};

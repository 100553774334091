import { isUndefined, omitBy } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import qs from 'query-string';

import { getRegionName } from '@/ducks/filtersOptions';
import { getFilterAgencyIsAvailable } from '@/ducks/fm/getters';
import { selectFilteredPackages } from '@/ducks/pages/chooseVoyage/selectors';
import { selectItineraryResultsRegions } from '@/ducks/pages/itineraryResults/selectors';
import { getAmountTaxAware, getAppliedDiscounts, getGrandTotal } from '@/helpers/data/mappers/Summary';
import { getPaymentPaymentOptionDesc } from '@/helpers/managers/payment';
import { getAllFilters, selectWaypoints } from '@/helpers/util/sailing';

import constants from '../../../constants';
import { createTracker } from '../../../tracker';
import builders from '../../builders';
import { mapAllFilters, transformFilter, transformPaymentMethod } from './transform';

const { AFFILIATION, EVENTS, VV_EVENTS_ROOT_PREFIX } = constants;

const FLOW_TYPES = {
  MNVV: 'MNVV',
};

const trackEvent = createTracker({
  category: builders.addRootPrefix(VV_EVENTS_ROOT_PREFIX, 'enhanced-ecommerce'),
  event: EVENTS.ENHANCED_ECOMMERCE,
  label: VV_EVENTS_ROOT_PREFIX,
});

export const generateECommerce = ({ list, sailing, state, subMetas, ...restFields }) =>
  subMetas.map((subMeta) => ({
    ...generateBaseProductFields({
      list,
      sailing,
      state,
    }),
    dimension5: subMeta?.name,
    dimension20: subMeta?.maxOccupancy || '',
    dimension21: subMeta?.balconySize || '',
    price: subMeta?.lowestAvailablePrice?.totalPrice?.amount || getGrandTotal(subMeta),
    variant: subMeta?.code || subMeta?.cabinTypeCode,
    ...restFields,
  }));

export const generateCabinProductsFromSailings = ({ list, sailings, state, ...restFields }) =>
  sailings?.map((sailing, index) => {
    const category = getRegionName(state, sailing.packageCode);

    const products = generateBaseProductFields({
      category,
      index,
      list,
      sailing,
      state,
      ...restFields,
    });
    return products;
  });

export const generateBaseProductFields = ({ index = 0, list, sailing, state, ...restFields }) => {
  const regions = selectItineraryResultsRegions(state);
  const brand = 'Virgin Voyages';
  // operator || used because position always positive value
  const position = sailing?.position || findSailingPosition(sailing, state) || index + 1;
  const queryParams = urlSearchParams();

  return {
    brand,
    category: getRegionNameFromRegions(sailing?.packageCode, regions),
    id: sailing?.id,
    name: sailing?.packageName,
    price: getAmountTaxAware(sailing?.startingPrice),
    ...(list && { list }),
    dimension2: queryParams.shipCode || sailing?.ship?.code,
    dimension3: sailing?.startDate,
    dimension4: sailing?.duration,
    dimension6: sailing?.packageCode,
    position,
    ...restFields,
  };
};

export const generateBaseCabinFields = ({ cabin, sailing, ...restFields }) => ({
  creative: cabin.name,
  id: sailing.id,
  name: sailing.packageName,
  position: cabin.recommendedSortPriority,
  ...restFields,
});

export const generatePurchaseActionField = ({ booking, cabinInvoice, paymentSignature, state, ...rest }) => {
  const { currencyCode: filterCurrencyCode } = getAllFilters(state);
  const { portFees = 0, taxAmount = 0 } = cabinInvoice.bookingPriceDetails;

  const isHoldTransaction = isEmpty(paymentSignature);
  const id = (isHoldTransaction ? booking?.reservationNumber : paymentSignature?.transactionId) ?? '';
  const tax = isHoldTransaction ? 0 : (taxAmount + portFees)?.toFixed(2);
  const revenue = paymentSignature?.amount ?? 0;
  const currency = paymentSignature?.currencyCode ?? filterCurrencyCode;
  const { codes: coupon } = getListAppliedDiscounts(cabinInvoice);
  const affiliation = AFFILIATION.VV_ECOMMERCE;

  return {
    affiliation,
    coupon,
    currency,
    id,
    revenue: String(revenue),
    tax: String(tax),
    ...rest,
  };
};

export const generatePaymentSummary = ({ booking, cabinInvoice, paymentSignature, sailing, state }) => {
  const {
    accessible,
    currencyCode,
    isUpsellApplied,

    isUpsellEnabled,
    sailors: filterSailorCount,
  } = getAllFilters(state);
  const reservationNumber = booking?.reservationNumber ?? '';
  const sailors = booking?.sailorCount ?? filterSailorCount;

  const paymentOptionDesc = getPaymentPaymentOptionDesc();
  const paymentMethod = transformPaymentMethod(paymentSignature?.paymentMode);

  const { amount = 0 } = getListAppliedDiscounts(cabinInvoice);
  const discount = Math.abs(amount);
  const accessKey = booking?.accessKey?.[0] ?? '';

  const { cityName, portIds } = selectWaypoints({ sailing });
  const value = getGrandTotal(cabinInvoice) || 0;

  return {
    access_key: accessKey,

    city: cityName,
    coupon_amount: discount,
    currencyCode,

    isAccessible: accessible,
    isUpsellApplied,

    isUpsellEnabled,
    pay_type_option: paymentOptionDesc,
    payment_method: paymentMethod,
    port_ids: portIds,

    res_id: reservationNumber,
    sailorCount: sailors,
    value,
  };
};

export const trackEcommerce = ({
  action,
  coupon_amount,
  currencyCode,
  ecommerce,
  isAccessible,
  isMNVV,
  pay_type_option,
  res_id,
  value,

  isUpsellApplied,
  isUpsellEnabled,
  sailorCount,
  ...trackEventProps
}) => {
  const fm = getFilterAgencyIsAvailable();

  const flowType = isMNVV ? FLOW_TYPES.MNVV : null;
  const isUpsell = isUpsellEnabled ? isUpsellApplied : undefined;

  const {
    currencyCode: transformedCurrencyCode,
    flow_type,
    upsell,
    ...transformedValues
  } = mapAllFilters(
    transformFilter({
      coupon_amount,
      currencyCode,
      flowType,
      fm,
      isAccessible,
      isUpsell,
      pay_type_option,
      res_id,
      sailorCount,
      value,
    }),
  );

  const nonEmptyValues = omitBy(transformedValues, isUndefined);

  trackEvent({
    ...trackEventProps,
    action,
    ecommerce: {
      ...(transformedCurrencyCode ? { currencyCode: transformedCurrencyCode } : {}),
      ...ecommerce,
    },
    ...nonEmptyValues,
    ...(upsell ? { upsell } : {}),
    ...(flow_type ? { flow_type: flowType } : {}),
  });
};

const getListAppliedDiscounts = (cabinInvoice) => {
  const appliedDiscount = getAppliedDiscounts(cabinInvoice?.bookingPriceDetails?.discountList);
  const codes =
    appliedDiscount?.codes && appliedDiscount?.codes?.length > 1
      ? appliedDiscount.codes
      : appliedDiscount?.codes[0] || '';
  const amount = appliedDiscount?.totalDiscount;

  return { amount, codes };
};

const getRegionNameFromRegions = (packageCode, regions = []) => {
  const region = regions.find((r) => r.packages.some((packageData) => packageData.id === packageCode));
  return region ? region.name : '';
};

const findSailingPosition = (sailing, state) => {
  const packages = selectFilteredPackages(state);
  const position = packages.findIndex((p) => p.packageCode === sailing.packageCode) + 1;
  return position;
};

const urlSearchParams = () => qs.parse(window.location.search, { parseBooleans: true, parseNumbers: true });

export default { trackEcommerce, trackEvent };
